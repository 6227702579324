import Head from 'next/head'
import { LoginPage } from '@upper/auth'

export default function Login() {
  return (
    <>
      <Head>
        <title>Login - Upper Fellow</title>
      </Head>

      <LoginPage appName="Fellow" noCredentials />
    </>
  )
}

Login.redirectAuthenticatedTo = '/'
